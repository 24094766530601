.auth-card {
  width: 100%;
  max-width: 440px;
  .p-card {
    padding: 2.5rem 3rem 1.25rem;
    width: 100%;
    text-align: center;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);

    .p-card-body,
    .p-card-content {
      padding: 0;
    }

    .field {
      text-align: left;
    }

    .logo {
      width: 100%;
      max-width: 232px;
    }
  }
}

ul.password-checklist {
  text-align: left;
  list-style: none;
  padding-left: 0.5rem;

  li {
    font-size: 0.75rem;
    line-height: 17px;
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;
    color: var(--status-gray);

    .icon {
      margin-right: 0.5rem;
      line-height: 0.5rem;

      .pi {
        font-size: 0.5rem;
      }

      .pi-check {
        color: var(--dark-green);
        font-weight: bolder;
      }

      .pi-circle-off {
        color: var(--status-gray);
      }
    }

    &.valid {
      .text {
        color: var(--dark-green);
      }
    }
  }
}
