.bordered-button {
  border-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
  border-width: 1px;
  border-style: solid;
}

.link {
  cursor: pointer;
  color: var(--xyz-button-blue);
}

.case-details-item .case-header-addon {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  gap: 1rem;
}
.case-details-item .case-details-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}
.case-details-item .case-sub-section-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
.case-details-item .text-normal {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.case-details-item .text-small {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}
.case-details-item .detail-field .label {
  font-weight: 400;
  font-size: 14px;
  color: var(--gray-500);
}
.case-details-item .detail-field .value,
.case-details-item .detail-field label {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.case-details-item .detail-field label {
  margin-left: 0.5rem;
}
.case-details-item .detail-field.checkbox-field {
  display: flex;
  align-items: center;
}
.case-details-item .detail-field.checkbox-field .p-checkbox .p-checkbox-box {
  opacity: 1;
}
.case-details-item .detail-field.radiobutton-field {
  display: flex;
  align-items: center;
}
.case-details-item .detail-field.radiobutton-field label {
  margin-left: 0.375rem;
}
.case-details-item .detail-field.radiobutton-field .p-radiobutton .p-radiobutton-box {
  opacity: 1;
}
.case-details-item .detail-field.infectious .p-checkbox .p-checkbox-box {
  border-color: #d32f2f;
}
.case-details-item .detail-field.infectious .p-checkbox .p-checkbox-box .p-highlight {
  border-color: #d32f2f;
  background: #d32f2f;
}
.case-details-item .detail-field.infectious .p-checkbox.p-checkbox-checked .p-checkbox-box {
  background: #d32f2f;
}
.case-details-item .case-address {
  font-size: 14px;
}
.case-details-item .case-address .institution {
  font-weight: 500;
}
.case-details-item .timeframe {
  font-weight: 500;
  color: var(--yellow-500);
  font-size: 14px;
  line-height: 17px;
}

.case-details-section-divider {
  border-bottom: 1px dashed var(--border-color);
  margin: 1rem 0;
}

.p-chip:not(.default) {
  background-color: transparent;
  border: 1px solid var(--color-primary-500);
  box-sizing: border-box;
  border-radius: 3px;
}
.p-chip:not(.default) .p-chip-text {
  color: var(--color-primary-500);
  font-size: 10px;
  line-height: 17px;
  margin: 0;
}

.dropdown-chip .p-chip {
  background: #eff6ff;
  color: var(--color-primary-500);
  border-radius: 6px;
  padding: 0 10px;
  max-width: 100%;
}
.dropdown-chip .p-chip .p-chip-text {
  font-size: 0.875rem;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown-chip .p-chip .pi-chip-remove-icon {
  margin-left: 10px;
}

.text-chip {
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}
.text-chip.success {
  background: var(--green-100);
  color: var(--green-800);
}
.text-chip.danger {
  background: var(--red-100);
  color: var(--red-800);
}
.text-chip.warning {
  background: var(--yellow-100);
  color: var(--yellow-800);
}
.text-chip.gray {
  background: var(--gray-100);
  color: var(--gray-800);
}

:root {
  --xyz-button-blue: #2196F3;
  --xyz-text-color: #495057;
}

.p-galleria .p-galleria-item-container {
  flex-direction: column;
}
.p-galleria .p-galleria-item-container .p-galleria-item-nav {
  top: 40%;
  background-color: var(--gray-300);
  color: var(--gray-500);
}
.p-galleria .p-galleria-item-container .p-galleria-item-nav:not(.p-disabled):hover {
  background-color: var(--gray-500);
  color: var(--gray-300);
}
.p-galleria .p-galleria-item-container .p-galleria-item {
  height: 400px;
}
.p-galleria .p-galleria-item-container .p-galleria-item img {
  width: 100%;
  display: block;
  height: 400px;
  object-fit: contain;
}
.p-galleria .p-galleria-item-container .p-galleria-item p-skeleton {
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: center;
}
.p-galleria .p-galleria-item-container .p-galleria-item p-skeleton .p-skeleton {
  width: 10rem !important;
  height: 10rem !important;
}
.p-galleria .p-galleria-item-container .p-galleria-caption {
  position: static;
  background-color: transparent;
  padding: 0.5rem 0;
}
.p-galleria .p-galleria-item-container .p-galleria-caption p-galleriaitemslot {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  width: 100%;
}
.p-galleria .p-galleria-item-container .p-galleria-caption p-galleriaitemslot textarea {
  resize: none;
  flex: 1 1 300px !important;
}
.p-galleria .p-galleria-thumbnail-container {
  background-color: transparent;
  gap: 0.5rem;
  align-items: center;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
  background-color: var(--gray-200);
  color: var(--gray-500);
  width: 70px;
  height: 70px;
  margin: 0;
  border-radius: 6px;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-items-container {
  display: flex;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-items {
  gap: 0.5rem;
  flex-wrap: wrap;
  justify-content: center;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-items .thumbnail-wrapper {
  width: 70px;
  height: 70px;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-items .thumbnail-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-items .p-galleria-thumbnail-item {
  flex: none !important;
}