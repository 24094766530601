mgl-map {
  height: 100%;
  width: 100%;
}

.marker {
  width: 50px;
  height: 50px;
  background-position: center bottom;
  background-size: contain;
  background-repeat: no-repeat;

  &.vehicle-marker {
    background-image: url('../img/vehicle_marker.svg');

    &.active {
      background-image: url('../img/vehicle_marker_active.svg');
    }
  }

  &.location-marker {
    background-image: url('../img/location_marker.svg');

    &.active {
      background-image: url('../img/location_marker_active.svg');
    }
  }

  &.reduced-opacity {
    opacity: 0.3;
  }

  &.cluster-marker {
    background-image: url('../img/cluster_marker.svg');
    display: flex;
    justify-content: center;
    font-size: 1.25rem;
    padding-top: 9px;
    color: #232323;
    font-weight: 600;
  }
}
.mapboxgl-ctrl {
  button:not(:disabled):hover {
    background: var(--color-primary-700);
    color: #ffffff;
    border-color: var(--color-primary-700);
  }
}
