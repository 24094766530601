.p-overlaypanel .p-overlaypanel-content {
  padding: 0.25rem 0;
}

.overlay-menu-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.overlay-menuitem-link {
  padding: 0.75rem 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  color: #495057;
  border-radius: 0;
  transition: box-shadow 0.2s;
  -webkit-user-select: none;
  user-select: none;

  &:not(.p-disabled):hover {
    background: #e9ecef;
  }

  .overlay-menuitem-icon {
    color: #6c757d;
    margin-right: 0.5rem;
  }

  &:not(.p-disabled):hover .overlay-menuitem-icon {
    color: #6c757d;
  }
}
