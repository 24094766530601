.tag-group {
  display: flex;
  gap: 0;

  xyz-shared-tag {
    &:first-child {
      .tag {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    &:last-child {
      .tag {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &:not(:first-child):not(:last-child) {
      .tag {
        border-radius: 0;
      }
    }
  }
}

.p-tag {
  &.p-tag-success {
    background-color: var(--green);
  }
  &.p-tag-danger {
    background-color: var(--red);
  }
}
