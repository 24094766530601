.p-paginator {
  .p-paginator-pages {
    .p-paginator-page.p-highlight {
      background: var(--color-primary-50);
      border-color: var(--color-primary-50);
    }
  }
}

.p-link:focus {
  box-shadow: 0 0 0 0.2rem var(--color-primary-200);
}
