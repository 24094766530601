.p-sidebar {
  .p-sidebar-header,
  .p-sidebar-footer {
    display: none;
  }

  .p-sidebar-content {
    background-color: white;
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .header-wrapper {
    max-height: 65px;
    min-height: 65px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    padding: 20px;
  }

  .header {
    margin-top: 0;
    font-size: 18px;
    font-weight: 700;
  }

  .content {
    padding: 20px;
    flex: 1;
    overflow: auto;
  }

  .footer {
    border-top: 1px solid var(--border-color);
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
  }
}
