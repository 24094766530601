.p-datatable {
  &.p-datatable-scrollable {
    .p-datatable-table {
      height: 100%;
    }
  }

  .p-datatable-thead {
    > tr {
      > th {
        font-weight: 500;
        font-size: 0.75rem;
        padding: 1rem 0.5rem;

        &:first-child {
          padding-left: 1rem;
        }

        &:last-child {
          padding-right: 1rem;
        }

        .pi {
          font-size: 0.75rem;
        }
      }
    }
  }
  .p-datatable-wrapper {
    background-color: #fff;
  }
  .p-datatable-loading-overlay {
    display: none !important;
  }

  .progress-bar-column {
    padding: 0;
  }

  .p-datatable-tbody {
    flex: 1;
    > tr {
      > td {
        font-size: 0.75rem;
        padding: 1rem 0.5rem;

        &:first-child {
          padding-left: 1rem;
        }

        &:last-child {
          padding-right: 1rem;
        }

        &.column-status {
          .indicator {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  .p-datatable-header {
    padding: 0.5rem;

    .p-inputtext {
      font-size: 0.875rem;
    }
  }

  .p-sortable-column {
    &.p-highlight {
      color: var(--color-primary-500);

      &:hover {
        color: var(--color-primary-500);
        background: var(--color-primary-50);

        .p-sortable-column-icon {
          color: var(--color-primary-500);
        }
      }

      .p-sortable-column-icon {
        color: var(--color-primary-500);
      }
    }
    &:focus {
      box-shadow: inset 0 0 0 0.15rem var(--color-primary-200);
    }
  }
}

.word-break {
  word-break: break-all;
}
