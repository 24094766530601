.toastr {
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
  position: relative;
  overflow: hidden;
  margin: 0 0 16px;
  padding: 0;
  width: 300px;
  border-radius: 6px;
  pointer-events: all;
  cursor: pointer;

  .toast-message-details {
    word-break: break-word;
  }

  .toast-wrapper {
    display: flex;
    flex: 1;

    .icon-wrapper {
      flex: 0 0 56px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;

      .pi {
        font-size: 30px;
      }
    }

    .content-wrapper {
      flex: 1;
      padding: 16px;

      .toast-title {
        font-weight: 700;
        font-size: 1rem;
        margin-bottom: 8px;
      }

      .toast-message-details {
        color: #495057;
        font-size: 14px;
        margin-bottom: 8px;

        .link {
          font-weight: 700;

          i.pi {
            margin-left: 3px;
            font-size: 13px;
            margin-right: 3px;
          }
        }
      }
    }
  }

  span.action {
    font-weight: 700;
  }

  .toast-close-button {
    width: 2rem;
    height: 2rem;
    color: #c8ccd8;
    top: 0;
    right: 3px;
    position: absolute;
  }

  &.toast-info,
  &.toast-success,
  &.toast-warning,
  &.toast-error {
    background-color: #fff;
    border: none;
  }

  &.toast-info {
    color: var(--color-primary-500);
    .toast-wrapper {
      .icon-wrapper {
        background-color: var(--color-primary-500);

        i.pi {
          &:before {
            content: '\e924';
          }
        }
      }
    }
  }

  &.toast-success {
    color: #8cc736;

    .toast-wrapper {
      .icon-wrapper {
        background-color: #8cc736;

        i.pi {
          &:before {
            content: '\e90a';
          }
        }
      }
    }
  }

  &.toast-warning {
    color: #f59e0b;

    .toast-wrapper {
      .icon-wrapper {
        background-color: #f59e0b;

        i.pi {
          &:before {
            content: '\e989';
          }
        }
      }
    }
  }

  &.toast-error {
    color: #ef4444;

    .toast-wrapper {
      .icon-wrapper {
        background-color: #ef4444;

        i.pi {
          &:before {
            content: '\e90c';
          }
        }
      }
    }
  }
}
