.p-dialog {
  .p-dialog-header {
    padding: 1rem;
    font-size: 1.125rem;
    font-weight: 500;
    border-bottom: 1px solid var(--border-color);
  }

  .p-dialog-content {
    padding: 1rem;

    .p-message-wrapper {
      flex-wrap: wrap;

      .p-message-detail {
        order: 4;
        flex: 0 0 100%;
        margin-top: 0.5rem;
        margin-left: 0;
      }
    }
  }

  .p-dialog-footer {
    border-top: 1px solid var(--border-color);
    padding: 1rem;

    button:last-child {
      margin-right: 0;
    }
  }

  &.case-form-dialog {
    max-height: 100%;
    min-height: 100%;

    .p-dialog-header {
      padding: 0;
      display: none;
    }
    .p-dialog-footer {
      display: none;
    }

    .p-dialog-content {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
      padding-bottom: 0;
      width: 100%;
      height: 100%;
      display: flex;
    }
  }

  &.no-header {
    .p-dialog-header {
      padding: 0;
      display: none;
    }
  }

  &.no-footer {
    .p-dialog-footer {
      display: none;
    }
  }

  &.narrow-content {
    .p-dialog-content {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
      padding-bottom: 0;
      width: 100%;
      height: 100%;
    }
  }

  &.new-case-dialog {
    max-height: 100%;
    min-height: 100%;
    .p-dialog-header {
      // height: 0;
      padding: 0;
      display: none;
    }
    .p-dialog-footer {
      display: none;
      // min-height: 100px;
    }
    .button-wrapper {
      position: absolute;
      top: 9px;
      right: 1rem;

      .p-button-danger {
        margin-right: 1rem;
      }
    }

    .p-dialog-content {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
      padding-bottom: 0;
      width: 900px;
      overflow-y: auto;
      height: 100%;
      // height: 100%;
      // padding-bottom: 1rem;

      .divider {
        &:first-child {
          margin-top: 0;
        }
      }
    }

    .field {
      .p-inputtext {
        width: 100%;
      }
      margin-bottom: 0;
    }

    .grid {
      margin-top: 0;
      margin-left: 10px;
      margin-right: 0;
      margin-bottom: 0;
    }

    .col-callback {
      padding-top: 23px;
    }

    .deceased-checkboxes {
      padding-top: 26px;
    }

    .field-checkbox {
      > label {
        margin-left: 0.5rem;
        cursor: pointer;
      }
    }

    .p-dropdown-label {
      font-size: 0.875rem;
    }
    .p-dropdown-panel {
      .p-dropdown-items .p-dropdown-item {
        font-size: 0.875rem;
      }
    }

    .infectious {
      .p-checkbox {
        .p-checkbox-box {
          border-color: var(--red);

          &.p-highlight {
            background: var(--red);
          }
        }

        &:not(.p-checkbox-disabled) {
          .p-checkbox-box.p-focus {
            box-shadow: 0 0 0 0.2rem var(--red-shadow);
          }
        }
      }

      label {
        color: var(--red);
      }
    }

    .location-of-valuables {
      textarea {
        min-width: 260px;
        height: 142px;
      }
    }
    .additional-information {
      textarea {
        width: 100%;
      }
    }

    .dropdown-grid {
      label {
        color: var(--status-gray);
        font-size: 0.75rem;
      }

      .p-dropdown {
        width: 100%;
        margin-bottom: 0.5rem;
      }
    }

    .target-address-dropdown {
      width: 200px;
    }
  }

  &.upload-dialog {
    max-height: 500px;
    height: 100%;
  }
}
