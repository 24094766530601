.p-chip:not(.default) {
  background-color: transparent;
  border: 1px solid var(--color-primary-500);
  box-sizing: border-box;
  border-radius: 3px;

  .p-chip-text {
    color: var(--color-primary-500);
    font-size: 10px;
    line-height: 17px;
    margin: 0;
  }
}

.dropdown-chip {
  .p-chip {
    background: #eff6ff;
    color: var(--color-primary-500);
    border-radius: 6px;
    padding: 0 10px;
    max-width: 100%;
    .p-chip-text {
      font-size: 0.875rem;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .pi-chip-remove-icon {
      margin-left: 10px;
    }
  }
}

.text-chip {
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;

  &.success {
    background: var(--green-100);
    color: var(--green-800);
  }
  &.danger {
    background: var(--red-100);
    color: var(--red-800);
  }
  &.warning {
    background: var(--yellow-100);
    color: var(--yellow-800);
  }
  &.gray {
    background: var(--gray-100);
    color: var(--gray-800);
  }
}
