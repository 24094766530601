.bordered-button {
    border-color: rgba(0, 0, 0, 0.25);
    border-color: rgba(0, 0, 0, 0.25);
    border-width: 1px;
    border-style: solid;
}

.link {
    cursor: pointer;
    color: var(--xyz-button-blue);
}