.field {
  > label {
    color: var(--status-gray);
    font-size: 0.75rem;
  }

  .p-inputtext {
    font-size: 0.875rem;
  }

  .p-inputnumber-button {
    .pi {
      font-size: 0.75rem;
    }
  }

  &.input-switch {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > label {
      font-size: 0.875rem;
      margin-bottom: 0;
      color: var(--text-color);
    }
  }

  > small {
    font-size: 0.6875rem;
  }

  &.inline-label {
    display: flex;
    align-items: center;

    > label {
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }

  p-autocomplete {
    // display: inline-block;
    // width: 100%;

    // .p-autocomplete {
    //   width: 100%;
    // }
  }
}

.p-input-icon-left-and-right {
  position: relative;
  display: inline-block;

  > i {
    position: absolute;
    top: 50%;
    margin-top: -0.5rem;
  }

  > i.input-left {
    left: 0.5rem;
    color: #6c757d;
  }
  > i.input-right {
    right: 0.5rem;
    color: #6c757d;
  }

  > .p-inputtext {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.p-input-icon-left > .p-icon-wrapper.p-icon,
.p-input-icon-left .p-fluid {
  .field:last-child {
    margin-bottom: 0;
  }
}

.p-checkbox {
  .p-checkbox-box {
    &.p-highlight {
      border-color: var(--color-primary-500);
      background: var(--color-primary-500);
    }
  }
}

.p-checkbox:not(.p-checkbox-disabled) {
  .p-checkbox-box {
    &.p-highlight:hover {
      border-color: var(--color-primary-500);
      background: var(--color-primary-500);
    }

    &.p-focus {
      box-shadow: 0 0 0 0.2rem var(--color-primary-200);
      border-color: var(--color-primary-500);
    }

    &:hover {
      border-color: var(--color-primary-500);
    }
  }
}

.field-checkbox,
.field-radioButton {
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  & > label {
    font-size: 14px;
    line-height: 17px;
    color: var(--text-color);
    margin-left: 0;
    margin-bottom: 0rem;
    cursor: pointer;

    &.icon-end {
      display: flex;
      align-items: center;

      img {
        height: 20px;
        margin-left: 10px;
      }
    }
  }
}

.fake-checkbox {
  &.p-radiobutton {
    .p-radiobutton-box {
      border-radius: 3px;

      .p-radiobutton-icon {
        width: auto;
        height: auto;
        font-family: 'primeicons';
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        display: inline-block;
        -webkit-font-smoothing: antialiased;
        font-size: 14px;
        border-radius: 0;
        box-sizing: border-box;
        transform: none;
        visibility: hidden;
        color: white;
        background-color: transparent;

        &:before {
          content: '\e909';
        }
      }

      &.p-highlight {
        background-color: var(--color-primary-500);

        .p-radiobutton-icon {
          visibility: visible;
        }
      }
    }
  }
}

.p-dropdown {
  &:not(.p-disabled) {
    &.p-focus {
      box-shadow: 0 0 0 0.2rem var(--color-primary-200);
      border-color: var(--color-primary-500);
    }
    &:hover {
      border-color: var(--color-primary-500);
    }
  }
}

.p-dropdown-panel {
  .p-dropdown-items {
    .p-dropdown-item.p-highlight {
      background: var(--color-primary-50);
    }
  }
}

.p-inputtext {
  &:enabled {
    &:focus {
      box-shadow: 0 0 0 0.2rem var(--color-primary-200);
      border-color: var(--color-primary-500);
    }

    &:hover {
      border-color: var(--color-primary-500);
    }
  }
}

.p-inputswitch {
  &.p-inputswitch-checked {
    .p-inputswitch-slider {
      background: var(--color-primary-500);
    }

    &:not(.p-disabled):hover {
      .p-inputswitch-slider {
        background: var(--color-primary-700);
      }
    }
  }

  &.p-focus {
    .p-inputswitch-slider {
      box-shadow: 0 0 0 0.2rem var(--color-primary-200);
    }
  }
}

.p-radiobutton {
  .p-radiobutton-box {
    &.p-highlight {
      background-color: var(--color-primary-500);
      border-color: var(--color-primary-500);

      &:not(.p-disabled):hover {
        background-color: var(--color-primary-700);
        border-color: var(--color-primary-700);
      }
    }

    &:not(.p-disabled) {
      &.p-focus {
        box-shadow: 0 0 0 0.2rem var(--color-primary-200);
        border-color: var(--color-primary-500);
      }

      &:not(.p-highlight):hover {
        border-color: var(--color-primary-500);
      }
    }
  }
}

.p-inputgroup-addon {
  font-size: 12px;
}

.phone-number-input {
  .p-inputgroup .p-inputwrapper > .p-component {
    width: 100%;
    height: 100%;
    padding: 0 0.4rem;
  }

  .p-inputgroup-addon {
    padding: 0;
  }

  .p-dropdown {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: none;
    background: inherit;

    .p-dropdown-trigger {
      width: 1.5rem;
      color: var(--status-gray);
    }

    .p-dropdown-label {
      color: var(--status-gray);
    }
  }

  .p-dropdown-filter-container {
    input {
      width: 100% !important;
    }
  }

  .p-dropdown-panel {
    min-width: 150px;
  }

  .p-dropdown-empty-message {
    font-size: 14px;
    padding-top: 0 !important;
  }

  .p-inputgroup .p-inputtext {
    flex: 1;
    min-width: 50px;
  }
}

.p-autocomplete.single-selection {
  .p-autocomplete-multiple-container {
    li {
      &:last-child {
        input {
          display: none;
        }
      }
      &:first-child {
        input {
          display: block;
        }
      }
    }
  }
}

.p-inputwrapper-filled {
  .p-autocomplete.single-selection {
    .p-autocomplete-multiple-container {
      cursor: default;
    }
  }
}

.p-inputnumber {
  width: 100%;
}
